h1, h2, h3, h4, h5 {
    color: var(--primary);
}

a {
    color: var(--primarylight);

    &:hover {
        color: var(--primary);
    }
}

.btn {
    background-color: var(--primary);
    color: var(--buttoncolor);
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color .1s ease;
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    font-size: 1rem;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background-color: var(--primarylight);
        color: var(--buttoncolor);
    }

    &-cancel {
        background-color: var(--primarydark);
    }

    &-small {
        font-size: .7rem;
    }

    &--has-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &--icon {
        margin-right: 8px;
        display: flex;
        align-items: center;

        svg {
            width: 14px;
            height: 14px;
            fill: #fff;
        }
    }

    &-mail {
        svg {
            width: 18px;
            height: 20px;
        }
    }

    &-back {
        background-color: transparent;
        color: var(--primary);
        padding: 0;
        display: flex;
        align-items: center;
        font-weight: bold;

        span {
            transform: translate3d(0, 0, 0);
            transition: transform .2s ease;
        }

        .btn-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;

            svg {
                fill: var(--primary);
            }
        }

        &:hover {
            background-color: transparent;
            color: var(--primarylight);

            svg {
                fill: var(--primarylight);
            }

            span {
                transform: translate3d(-3px, 0, 0);
            }
        }
    }

    &--loading {
        position: relative;

        & > span {
            opacity: 0;
        }

        & > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &:disabled {
        background-color: var(--bordercoloralt);
        cursor: default;

        &.btn--loading {
            background-color: #e8e8ee;
        }
    }
}

.icon-btn {
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover {
        svg {
            fill: var(--primary);
        }
    }
}

.input-wrapper {
    margin-bottom: 16px;

    label {
        span {
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
        }
    }

    &--input {
        width: 100%;
    }

    .css-b62m3t-container {
        min-width: 160px;
        max-width: 160px;
    }

    .css-at12u2-loadingIndicator,
    .css-1xh8qep-loadingIndicator {
        span {
            font-size: 4px;
        }
    }

    .css-1pahdxg-control {
        box-shadow: 0 0 0 1px var(--primarylight);
        border-color: var(--primarylight);

        &:hover {
            border-color: var(--primarylight);
        }
    }
    
    .css-26l3qy-menu {
       z-index: 999;

        .css-14k70rn-MenuList,
        .css-4ljt47-MenuList {
            box-shadow: 0 0 0 1px rgba(0, 0, 0,.1), 0 4px 11px rgba(0, 0, 0,.1);
            background-color: #fff;
            border: 1px solid var(--primarylight);
            border-radius: 4px;
            min-width: 160px;
        }
    }
    
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 30px;
        max-height: 32px;
        background-color: var(--basecolor);
        border-color: var(--bordercoloralt);

        &:hover {
            border-color: var(--bordercoloralt);
        }
    }

    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .css-qc6sy-singleValue {
        font-size: 14px;
        color: var(--fontcolor);
    }

    .css-9gakcf-option,
    .css-1n7v3ny-option {
        background-color: var(--primarylight);
    }

    .css-1n7v3ny-option {
        background-color: var(--boxcolor);
    }

    input:not([type=checkbox]),
    textarea {
        display: block;
        width: 100%;
        height: 100%;
        padding: 7px 10px;
        border-radius: 4px;
        -webkit-appearance: none;
        border: 1px solid var(--bordercoloralt);
        background-color: var(--basecolor);
        color: var(--fontcolor);
        transition: border-color .2s ease;
        font-size: 14px;
        font-family: 'Ubuntu', sans-serif;

        &:disabled {
            background-color: var(--bordercolor);
        }

        &:focus {
            outline: 0;
        }
    }

    textarea {
        resize: vertical;
        min-height: 120px;
    }

    &--control {
        display: flex;
        align-items: center;

        &-focused {
            .input-wrapper--input {
                position: relative;
                box-shadow: 0 0 0 1px var(--primarylight);
                border-color: var(--primarylight);
                border-radius: 4px;

                input,
                textarea {
                    border-color: var(--primarylight);
                }
            }
        }

        &-icon {
            position: absolute;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            transform: translateY(-50%);
            z-index: 4;

            svg {
                width: 14px;
                height: 14px;
                fill: var(--primarylight);
            }
        }
    }

    &--has-icon {

        .input-wrapper--control {
            position: relative;
        }

        input:not([type=checkbox]) {
            padding-left: 30px;
        }
    }

    &--suffix {
        margin-left: 10px;
    }

    &--duration {
        
        .input-wrapper {
            &--control {
                display: flex;
            }

            &--input {
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }

            &--suffix {
                margin-right: 10px;
            }
        }
    }

    &--checkbox {
        margin-bottom: 0;

        &-box {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: var(--basecolor);
            width: 30px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid var(--bordercolor);

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) scale(0);
                pointer-events: none;
                opacity: 0;
                transition: all .12s ease-in-out;
                fill: var(--primarylight);
            }
        }

        &-disabled {

            .input-wrapper--checkbox-box {
                background-color: var(--boxcolor);
            }
            svg {
                fill: var(--bordercoloralt);
            }
        }

        &-checked {
            svg {
                opacity: 1;
                transform: translate3d(-50%, -50%, 0) scale(1);
            }
        }

        label {
            display: flex;
            align-items: center;

            input {
                opacity: 0;
                position: absolute;
                pointer-events: none;
            }

            span {
                margin-bottom: 0;
                margin-left: 8px;
            }
        }
    }

    &--error {

        label {
            & > span {
                color: rgb(255, 0, 85);
            }
        }
        
        .input-error {
            font-size: 14px;
            color: var(--bordercoloralt);
            padding: 6px 0 0;
        }
    }
}

.inner {
    padding: 60px 8px;
    max-width: var(--maxWidth);
    margin: 0 auto;

    &-title {
        display: flex;
        align-items: center;

        .btn {
            margin-right: 20px;
            
            &:nth-of-type(1) {
                margin-left: 40px;
            }
        }

        &--actions {
            display: flex;
            margin-bottom: 10px;
            
            & > * {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.loading-view {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.below-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}