.societies {

    .filters {
        &-search {
            max-width: 300px;
        }
    }
    
    .filter-table {

        td, th {

            &:first-child {
                width: 60%;
            }

            &:nth-child(2) {
                width: 100px;
            }

            &:nth-child(3) {
                width: 40%;
            }
        }
    }
}

#modal .modal-society.modal-inner {
    max-width: 540px;

    .error-message {
        padding-top: 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}