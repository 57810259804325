.select-role {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 50px - 78px);

    .inner {
        max-width: 240px;
        width: 100%;

        &-title {
            display: block;
            
            h3 {
                text-align: center;
            }
        }
    }

    &-content {
        text-align: center;

        .btn {
            display: block;
            margin: 10px auto;
            width: 100%;
        }
    }
}