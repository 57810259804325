:root {
    --maxWidth: 1240px;
    
    .theme--light {
        --primary: #010C3A;
        --primarylight: #9BABBA;
        --primarydark: #121625;

        --secondary: #71088D;

        --basecolor: #ffffff;
        --basecoloralt: #f9f9fb;

        --boxcolor: #f4f4f6;

        --fontcolor: #242425;
        --fontcoloralt: #4b4b4d;

        --bordercolor: #e0e4e9;
        --bordercoloralt: #c9cace;

        --buttoncolor: #fff;

        --error: rgb(245, 20, 73);
        --success: rgb(1, 201, 108);
    }
}