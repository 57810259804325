.authors-reports {

    .filters {
        display: flex;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;

        td, th {

            &:first-child {
                width: 40%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 240px;
            }
        }
    }
}