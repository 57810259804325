.profile {

    &-content {
        display: flex;
    }

    &-info {
        flex: 1;
        max-width: 400px;
        padding-right: 20px;
        border-right: 1px solid var(--boxcolor);
        margin-right: 20px;
    }

    &-inheritance {
        flex: 1;
    }

    &-heirs {
        margin-bottom: 40px;
    }

    &-heirs,
    &-authors {
        flex: 1;

        h3 {
            margin-top: 0;
        }

        &--list {
            display: grid;
            grid-template-columns: repeat(3, 33.333%);
            gap: 20px;
        }

        &--item {
            background-color: var(--boxcolor);
            padding: 20px;
            border-radius: 10px;

            h4 {
                margin-top: 0;
            }

            &-percentage {
                max-width: 100px;
            }
        }

        &--add {
            width: 300px;

            &-percentage {
                max-width: 100px;
            }
        }
    }
}