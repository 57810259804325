.users {

    .filters {
        position: relative;
        z-index: 4;

        &-search {
            display: flex;

            &--role {
                width: 100%;
                max-width: 200px;
                margin-left: 20px;
            }
        }
    }
    
    .filter-table {

        td, th {

            &:first-child {
                width: 30%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 100px;
            }

            &:nth-child(4) {
                width: 20%;
            }

            &:nth-child(5) {
                width: 20%;
            }
        }
    }
}