.creation {

    &-content {
        display: flex;
        justify-content: space-between;

        & > div {
            flex: 1;
        }

        .creation-meta {
            padding-right: 20px;
            border-right: 1px solid var(--boxcolor);

            &--item {
                label {
                    
                    span {
                        text-transform: uppercase;
                        font-size: .6rem;
                        font-weight: 700;
                        letter-spacing: .03rem;
                    }
                }

                p {
                    margin-top: 5px;
                }

                &-tvshow {
                    display: flex;

                    .input-wrapper {
                        flex: 1;

                        &:first-child {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .distribution-info {
        margin: 0 0 20px;
        display: flex;

        div {
            &:first-child {
                margin-right: 20px;
            }
        }
    }
}