.login {
    width: 100%;
    min-height: 100vh;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        background-color: #fff;
        padding: 40px 20px;
        border-radius: 20px;
        max-width: 600px;
        width: 100%;
        min-height: 352px;

        & > p {
            text-align: center;
        }
    }

    .brand {
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-logo {
            background-image: url('../../../public/logo-blue.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50%;
            width: 100%;
            max-width: 104px;
            height: 50px;
            margin-right: 20px;
        }
    }

    .eaal-logo {
        background-image: url('../../../public/eaal-logo-blue.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 100%;
        max-width: 147px;
        height: 37px;
    }

    .methods {
        display: flex;
        justify-content: center;

        &-item {
            display: flex;
            align-items: center;
            padding: 10px 14px;
            border-radius: 30px;
            border: 2px solid var(--bordercolor);
            cursor: pointer;
            margin: 0 10px;

            h4 {
                margin: 0;
            }

            &--image {
                margin-right: 10px;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 30px;
                    display: block;
                }
            }
        }

        &-user {
            text-align: center;
            margin: 20px 0 0;

            a {
                color: var(--primarylight);
                text-decoration: underline;
                cursor: pointer;
                font-size: .8rem;
            }

            &-forgot {
                max-width: 600px;
            }
        }
    }

    &-method {

        &--user {

            .login-method--error {
                color: var(--error);
            }
            a {
                display: block;
                color: var(--primarylight);
                text-decoration: underline;
                cursor: pointer;
                font-size: .8rem;
                text-align: center;
                margin-top: 20px;
            }

            &-forgot-sent {
                text-align: center;
            }
        }
    }
}