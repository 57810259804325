.user-tabs {

    .inner-title {
        display: block;

        h1 {
            margin-bottom: 10px;
        }

        h4 {
            margin-top: 0;
        }
    }

    &--items {
        display: flex;
        margin-bottom: 40px;
    }

    &--item {
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: bold;
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: var(--primarylight);
        }

        &-active {
            text-decoration: underline;

            &:hover {
                color: var(--primary);
            }
        }
    }

    &--content {
        .inner {
            padding: 0;
        }
    }

    .filters {
        display: flex;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;

        td, th {

            &:first-child {
                width: 40%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 240px;
            }
        }
    }
}