.app-footer {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(black, .08);
    z-index: 9;

    &--inner {
        width: 100%;
        padding: 20px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: var(--maxWidth);
    }

    .brand {
        position: relative;
        z-index: 13;
        display: flex;
        align-items: center;

        &-logo {
            width: 88px;
            height: 38px;
            background-image: url('../../public/logo-white.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 10px;
        }
    }

    .eaal-logo {
        width: 105px;
        height: 30px;
        background-image: url('../../public/eaal-logo-white.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .partners {

        &--tmdb {
            width: 100px;
            height: 15px;
            background-image: url('../../public/tmdb-logo.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}