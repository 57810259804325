.foreign {

    .filters-foreign {
        display: flex;

        .filters-search {
            margin-right: 20px;
        }
    }
    .filter-table {

        td, th {

            &:first-child {
                width: 30%;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 300px;
            }

            &:nth-child(5) {
                width: 40%;
            }

            &:nth-child(6) {
                width: 100px;
            }
        }
    }
}

#modal .modal-society.modal-inner {
    max-width: 540px;

    .error-message {
        padding-top: 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}