.creations {

    .filters {
        &-search {
            max-width: 300px;
        }
    }
    
    .filter-table {

        td, th {

            &:first-child {
                width: 30%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 100px;
            }

            &:nth-child(4) {
                min-width: 60px;
                width: 60px;
            }

            &:nth-child(5) {
                min-width: 100px;
                width: 100px;
            }

            &:nth-child(6) {
                width: 20%;
            }

            &:nth-child(6) {
                width: 20%;
            }
        }
    }
}