.login-method {
    width: 100%;
    
    &--logo {
        text-align: center;
        margin-bottom: 30px;

        img {
            max-width: 100px;
            max-height: 100px;
        }
    }

    .verification-code {
        text-align: center;
        
        span {
            font-weight: 600;
            font-size: 1.8rem;
        }
    }

    &--input {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;

        &-phone {

            label {
                display: block;
                font-size: 12px;
                margin-bottom: 5px;
            }
            
            & > div {
                display: flex;

                .input-wrapper {

                    &:first-child {
                        max-width: 60px;

                        input {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        .input-wrapper--control-focused {
                            .input-wrapper--input {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                        }
                    }

                    &:last-child {
                        width: 100%;

                        input {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }

                        .input-wrapper--control-focused {
                            .input-wrapper--input {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .login-actions {
        text-align: right;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .btn {
            width: 100%;

            &-cancel {
                margin-right: 20px;
            }
        }
    }

    &--error {
        
        &-message {
            background-color: var(--error);
            padding: 10px;
            color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                margin: 0;

                a {
                    color: var(--buttoncolor);
                }
            }
        }

        .login-actions {
            .btn-cancel {
                margin-right: 0;
            }
        }
    }
}

.filter-table {
    padding-bottom: 50px;
    position: relative;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    &--container {
        position: relative;
        .loading-view {
            position: absolute;
            min-height: calc(100vh - 300px);
        }
    }

    table {

        th {
            position: sticky;
            top: 50px;
            background-color: var(--basecoloralt);
            z-index: 3;
            min-width: 100px;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            input {
                box-sizing: border-box;
                width: 100%;
                font-size: 12px;
                -webkit-appearance: none;
                border: 1px solid var(--bordercoloralt);
                background-color: var(--basecolor);
                border-radius: 4px;
                padding: 3px 6px;
                color: var(--fontcolor);

                &:focus {
                    outline: none;
                    border-color: var(--primarylight);
                }
            }
        }

        tbody {

            tr {
                cursor: pointer;

                &:hover {
                    td {
                        background-color: var(--basecoloralt);
                    }
                }
            }

            td {
                min-width: 100px;
                white-space: normal;
                padding: 8px;
                font-size: .9rem;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
    
                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }

    &-row {
        &--selected {
            td {
                background-color: var(--basecoloralt);
                color: var(--primarylight);

                &:first-child {
                    border-left: 3px solid var(--primarylight);
                }
            }
        }
    }

    &-header {
        &--label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 14px 8px;

            span {
                display: block;
                font-size: .6rem;
                text-align: left;
                color: var(--primary);
                letter-spacing: .05rem;
                text-transform: uppercase;
                padding-right: 20px;
            }
        }
        &--sort {
            margin-left: 10px;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 10px;
                height: 10px;
                transform: rotate(-90deg);
            }

            &-asc {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }
}


.pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: var(--basecoloralt);
    max-width: var(--maxWidth);
    border-radius: 10px;
  
    &-prev,
    &-next {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      line-height: 26px;
      display: flex;
      align-items: center;
  
      &:hover {
        color: var(--fontcoloralt);

        svg {
            fill: var(--primarylight);
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-prev {
        svg {
            transform: rotate(90deg);
        }
    }

    &-next {
        svg {
            transform: rotate(-90deg);
        }
    }
  
    &-pages {
      background-color: var(--basecoloralt);
      padding: 5px 10px;
      border-radius: 6px;
      font-size: 14px;
      pointer-events: none;
    }
  
    .disabled {
      opacity: 0;
      pointer-events: none;
    }
}

.file-input {
    span {
        color: var(--basecolor);
        background-color: var(--primary);
        padding: 8.25px 16px;
        border-radius: 6px;
        display: inline-block;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: var(--primarylight);
        }
    }

    &--filename {
        font-size: 14px;
    }

    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
}

.upload-file {
    max-width: 400px;

    &--type {
        display: flex;
        align-items: flex-end;

        .input-wrapper {
            margin-bottom: 0;
            width: 200px;
        }

        & + .btn {
            margin-top: 10px;
        }
    }

    &--file {
        .btn-small {
            margin-bottom: 10px;
        }
    }

    &--filename {
        font-size: 14px;
        margin: 10px 0;
    }

    .file-input {

        &--filename {
            display: none;
        }
    }
}

#modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {
        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 840px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }

    .modal-bonus {
    
        &.modal-inner {
            max-width: 400px;
        }
        
        .css-b62m3t-container {
            max-width: 100%;
            width: 100%;
        }
    }

    .modal-email {
        max-width: 600px;

        &--success {
            text-align: center;

            svg {
                width: 100px;
            }
        }
    }

    .modal-foreign {
        &--inputs {
            display: grid;
            grid-template-columns: repeat(3, auto);
            column-gap: 14px;
        }

        .css-b62m3t-container {
            max-width: 100%;
            width: 100%;
        }
    }
}

.error-message {
    padding: 10px 0;

    span {
        display: block;
        color: var(--error);
        font-size: .9rem;
        white-space: pre-wrap;
    }
}

.success-message {
    &--container {
        margin: 8px 0;
    }

    &--message {
        display: inline-block;
        position: relative;
        padding: 10px 20px;
        background-color: var(--success);
        border-radius: 4px;

        span {
            display: block;
            color: #fff;
            font-size: .8rem;
            white-space: pre-wrap;
        }
    }
}

.error-message {
    &--container {
        margin: 8px 0;
    }

    &--message {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        background-color: var(--error);
        border-radius: 4px;

        span {
            display: block;
            color: #fff;
            font-size: .8rem;
            white-space: pre-wrap;
        }
    }
}

.success-message,
.error-message {

    &--message {
        padding-left: 40px;

        svg {
            position: absolute;
            width: 14px;
            height: 14px;
            fill: #fff;
            left: 0;
            top: 50%;
            transform: translate3d(20px, -50%, 0);
        }
    }
}

.modal-views {
    .views-map {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }
}

.modal-report {
    .budget {
        margin: 10px 0 20px;
    }

    .btn {
        margin-top: 40px;
    }
}

#modal .modal-export {
    .filters {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        align-items: center;

        .input-wrapper {
            margin-right: 10px;

            &:last-child {
                margin-left: 0;
            }
        }

        &-extra {
            h4 {
                margin-top: 0;
            }

            &--items {
                display: flex;
                margin-bottom: 20px;

            & > div {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
            }
        }
    }

    &--foreign,
    &--bonus {
        max-width: 500px;
    }

    &--actions {
        display: flex;
        align-items: center;

        .btn {
            margin-right: 20px;
        }
    }
}

.tasks {
    border-radius: 10px;
    background-color: var(--boxcolor);
    padding: 14px;
    margin-bottom: 20px;
    max-width: 700px;

    h5 {
        margin-top: 0;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        border-bottom: 1px solid var(--bordercolor);
        padding-bottom: 10px;

        &:last-child {
            border-bottom: none;
        }

        & > span {
            margin-right: 20px;
        }
    }
}

#confirm-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {

        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 440px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }
}

.react-datepicker {
    border-color: var(--bordercolor);
    box-shadow: 0 3px 16px rgba(black, .2);
    z-index: 999;

    &__header {
        background-color: var(--basecoloralt);
        border-bottom-color: var(--bordercolor);
    }

    &__navigation-icon {
        &::before {
            border-color: var(--primarylight);
        }
    }

    &__day {
        color: var(--fontcolor);

        &-name {
            color: var(--fontcolor);
        }

        &:hover {
            background-color: var(--boxcolor);
        }

        &--selected,
        &--keyboard-selected {
            background-color: var(--primarylight);

            &:hover {
                background-color: var(--primary);
            }
        }
    }

    &__month {
        &-container {
            background-color: var(--basecolor);
        }
    }

    &__current {
        &-month {
            color: var(--fontcolor);
        }
    }

    &-popper[data-placement^=bottom]{

        .react-datepicker {
            &__triangle {
                margin-left: -14px;

                &::after {
                    border-bottom-color: var(--basecoloralt);
                }

                &::before {
                    border-bottom-color: var(--bordercolor);
                }
            }
        }
    }

    &-popper[data-placement^=top]{

        .react-datepicker {
            &__triangle {
                margin-left: -14px;

                &::before {
                    border-top-color: var(--bordercolor);
                }
            }
        }
    }
}

.total-sum {
    text-align: right;
    margin-bottom: 10px;

    div {
        font-size: .9rem;

        &:last-child {
            font-size: .8rem;
            margin-top: 4px;
        }
    }
}

.creation-authors {
    padding-left: 20px;

    h3 {
        margin-top: 0;
    }

    &--item {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid var(--boxcolor);
        padding-bottom: 10px;

        &-user {
            cursor: pointer;

            .creation-authors--item-name {
                color: var(--primarylight);
            }
        }

        &-meta {
            display: flex;
            margin-top: 10px;

            & > div {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    &--add {
        margin-top: 20px;
        max-width: 400px;

        .input-wrapper {
            .css-b62m3t-container {
                max-width: 100%;
            }
        }
    }
}