.user-bonuses {

    .inner-title {
        display: block;

        h1 {
            margin-bottom: 10px;
        }

        h4 {
            margin-top: 0;
        }
    }

    .filters-bonus {
        display: flex;

        .filters-search {
            margin-right: 20px;
        }
    }

    .filter-table {

        td, th {

            &:first-child {
                width: 50%;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 50%;
            }

            &:nth-child(4) {
                width: 300px;
            }
        }
    }
}