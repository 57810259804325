.society {

    &-content {
        display: flex;
    }

    &-info {
        padding-right: 20px;
        border-right: 1px solid var(--bordercolor);
        max-width: 300px;
        width: 100%;
    }

    &-roles {
        padding: 0 20px;
        border-right: 1px solid var(--bordercolor);
    }

    &-types {
        padding-left: 20px;
    }

    &-types,
    &-roles {
        max-width: 400px;
        width: 100%;

        &--item {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid var(--bordercolor);
            align-items: center;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}