.settings {

    &-sections {
        margin-bottom: 30px;
        
        a {
            margin-right: 20px;
        }
    }

    &-points {
        display: flex;
        padding-bottom: 60px;

        &--table {
            border-right: 1px solid var(--boxcolor);
            padding-right: 20px;
        }

        &--item {
            .input-wrapper {
                margin-bottom: 0;
            }
        }

        table {

            th {
                font-size: .7rem;
                text-align: left;
                background-color: var(--boxcolor);
                padding: 8px 10px;
            }

            td {
                padding: 4px 10px;
            }

            tr {
                td {
                    border-bottom: 1px solid var(--boxcolor);
                }

                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }

        &--add {
            max-width: 400px;
            padding-left: 20px;

            &-between {
                display: flex;

                .input-wrapper {

                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    &-distribution {
        display: flex;
        padding-bottom: 60px;

        &--table {
            border-right: 1px solid var(--boxcolor);
            padding-right: 20px;
            flex: 0 1 300px;
        }

        &--item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--boxcolor);
            margin-bottom: 8px;
            padding-bottom: 10px;

            .input-wrapper {
                max-width: 100px;
                margin: 0;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }

        &--add {
            max-width: 400px;
            padding-left: 20px;

            &-between {
                display: flex;

                .input-wrapper {

                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }

        .total {
            display: block;
            text-transform: uppercase;
            font-size: .7rem;
            font-weight: bold;
            text-align: right;
        }
    }
}