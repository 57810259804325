.views {

    .filters {
        display: flex;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;

        td, th {

            &:first-child {
                width: 20%;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 60%;
            }

            &:nth-child(4) {
                width: 10%;
                text-align: right;

                span {
                    text-align: right;
                }
            }
        }
    }
}