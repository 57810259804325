.author-distributions {

    .inner-title {
        display: block;

        h1 {
            margin-bottom: 10px;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .filters {
        display: flex;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;

        td, th {

            &:first-child {
                width: 40%;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 30%;
            }

            &:nth-child(5) {
                width: 200px;
            }
        }

        tbody {

            tr {
                td {
                    &:first-child {
                        text-transform: capitalize;
                    }
                }

                &.is-user {
                    td {
                        &:first-child {
                            text-decoration: underline;
                            color: var(--secondary);
                        }
                    }
                }
            }
        }
    }
}