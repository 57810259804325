.reports {

    .inner-title {
        justify-content: space-between;

        &--left,
        &--right {
            display: flex;
            align-items: center;
        }
    }

    .filters {
        display: flex;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;

        td, th {

            &:first-child {
                width: 30%;
            }

            &:nth-child(2) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 20%;
            }

            &:nth-child(5) {
                width: 20%;
            }
        }
    }
}