.budget {

    &-content {
        display: flex;
    }

    &-info {
        border-right: 1px solid var(--boxcolor);
        padding-right: 20px;
        flex: 1;
        max-width: 400px;
    }

    &-items {
        width: 100%;

        &--item {
            background-color: var(--boxcolor);
            margin-bottom: 20px;
            border-radius: 10px;

            h4 {
                font-size: 1rem;
                margin: 0;
                padding: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            &-content {
                padding: 0 20px 20px;
                display: none;
            }

            &-open {

                .budget-items--item-content {
                    display: block;
                }

                h4 {

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &-add {
        max-width: 400px;
        padding-left: 20px;

        .input-wrapper {

            &--checkbox {
                margin-bottom: 20px;
            }
        }
    }
}