.unrecognized {

    .inner {
        min-height: calc(100vh - 78px);
    }

    &.app-content {
        min-height: 0;
    }

    .inner-title {
        position: sticky;
        top: 50px;
        background-color: #fff;
    }

    .filters {
        display: flex;
        align-items: center;
        position: sticky;
        top: 130px;
        z-index: 5;

        .input-wrapper {
            margin-right: 20px;
        }
    }
    
    .filter-table {
        user-select: none;
        height: calc(100vh - 80px - 67px - 198px);
        overflow-x: scroll;

        th {
            top: 0;
        }

        td, th {

            &:first-child {
                width: 30%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 200px;
            }

            &:nth-child(3) {
                width: 200px;
            }

            &:nth-child(5) {
                width: 10%;
            }

            &:nth-child(6) {
                width: 15%;
            }

            &:nth-child(7) {
                width: 15%;
            }
        }

        .pagination {
            position: fixed;
            bottom: 92px;
        }
    }
}